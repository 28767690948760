:root {
  --accent-color: #F4FE0E;
  --title-color: #1B1B1B;
  --body-color: #3B3B3B;
  --black-color: #000000;
  --white-color: #ffffff;
  --smoke-color: #F7F7F7;
  --text-color:#000000;
  --text-light-color:#ffffff;
  --font-size: 16px;
  --icon-size: 40px;

  --page-margin:16px;
  --top-bar-size:80px;
}

.App { 
  color: var(--text-color);
}

.App-header {
  background-color: #282c34;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url('res/Colonnato_piazza_Pitagora_Crotone_1080.webp') no-repeat center center fixed;
  background-size: cover;

  margin-top: var(--top-bar-size);
  min-height: calc(100vh - var(--top-bar-size));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin);
  color: var(--text-light-color);
}
.phone-number {
  color: var(--text-light-color);
  text-decoration: none;
}
.phone-number:hover {
  color: var(--accent-color);
}
.App-logo {
  height: var(--icon-size);
  width: var(--icon-size);
  line-height: var(--icon-size);
  margin-bottom: -14px;
}

/*contacts-top*/
.contacts-top{
  padding: 16px 0;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  top: 0;
  background-color: var(--white-color);
}

.contacts-links{
  padding: 0 8px;
}
.contacts-links > a{
  color: var(--text-color);
  text-decoration: none;
}
.contacts-links > a > span:hover {
  color: var(--accent-color);
}
.contacts-links > a > .icon-btn{
  color: var(--text-color);
  text-decoration: none;
  background-color:var(--accent-color);
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  text-align: center;
  border-radius: 50%;
  border: none;
  display: inline-block;
  margin-left: 8px;
}
.contacts-links > a > .icon-btn:hover {
  color: var(--text-color);
}
.contacts-links > a > .txt{
  margin-left: 8px;
}
@media (max-width: 767px) {
  .contacts-links > a > .txt.mail{
    display : none;
  }
}
@media (max-width: 467px) {
  .contacts-links > a > .txt.phone{
    display : none;
  }
}

.contacts-social a {
  background-color: var(--title-color);
  border: 1px solid var(--body-color);
  color: var(--white-color);
  display: inline-block;
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  background-color: var(--white-color);
  color: var(--body-color);
  font-size: 16px;
  text-align: center;
  border-radius: 50%;
  margin-right: 4px;
}
.contacts-social a:hover{
  color: var(--text-color) ;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

/*General*/
.space-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.flex{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.wrap{
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.nomargin{
  margin: 0px 0px;
}
.align-center {
  text-align: center;
}
